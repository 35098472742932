import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRegister: 0,
  wappBusiness: { wallet_balance: 0 }, //  To store the walet balance
  unreadChatCounts: 0, //  To show te unread message counts in sidebar mesage icon
  connectedUsers: {},
  campaignDetails: [],
  unreadNotification: 0,
  businessProfileData: null,
  usedMessageQuota: 0,
  incomingMessage: {},
  campaignDetailsUpdates: {},
  freeTierConversations: 0,
  subscriptionsCounts:0,   // For store the subscription count 
  newCustomer:{},
  messageStatusUpdate:{},
  updateMediaMessageIdToChatscreen:{}
  // notifications: [],
  // newMessages: []
  // permissions: {},
};

const wappSlice = createSlice({
  name: 'wappbiz',
  initialState,
  reducers: {
    addAction: (state, action) => {
      const { payload, type } = action.payload;
      switch (type) {
        case 'business':
          state.wappBusiness = payload;
          state.isRegister = 1;
          break;
        case 'updateWalletAmount':
          state.wappBusiness.wallet_balance =
            state.wappBusiness.wallet_balance + payload;
          break;
        case 'unreadChatCount':
          state.unreadChatCounts = state.unreadChatCounts + payload;
          break;
        case 'unreadNotification':
          state.unreadNotification = state.unreadNotification + payload;
          break;
        case 'connectedUsers':
          state.connectedUsers = payload;
          break;
        case 'campaignDetails':
          state.campaignDetails = payload;
          break;
        case 'campaignDetailsUpdate':
          const index = state.campaignDetails.findIndex(
            (detail) =>
              detail.name.toLowerCase() === payload.status.toLowerCase()
          );
          if (index !== -1) {
            state.campaignDetails[index].value += 1;
          }
          state.campaignDetailsUpdates = payload;
          break;
        case 'businessProfileData':
          state.businessProfileData = payload;
          break;
        case 'usedMessageQuota':
          state.usedMessageQuota = payload;
          break;
        case 'freeTierConversations':
          state.freeTierConversations = payload;
          break;
        case 'incomingMessage':
          state.incomingMessage = payload;
          break;
        case 'clearMessage':
          state.incomingMessage = {};
          break;
        case 'subscriptionsCounts':
          state.subscriptionsCounts = payload;
          break;
        case 'newCustomer':
          state.newCustomer = payload;
          break;
        case 'updateMediaMessageIdToChatscreen':
          state.updateMediaMessageIdToChatscreen = payload;
          break;
        case 'messageStatusUpdate':
          state.messageStatusUpdate = payload;
          break;
          
        // case 'permission':
        //   state.permissions = payload;
        //   break;
        default:
          break;
      }
    },
    removeAction: (state, action) => {
      //state.chats = state.chats.filter((chat) => chat.id !== action.payload);

      const { payload, type } = action.payload;
      switch (type) {
        case 'unreadChatCount':
          state.unreadChatCounts = state.unreadChatCounts - payload;
          break;
        case 'unreadNotification':
          state.unreadNotification = payload;
          break;
        case 'connectedUsers':
          state.connectedUsers = payload;
          break;
        case 'campaignDetails':
          state.campaignDetails = payload;
          break;
        default:
          break;
      }
    },
    resetState: () => initialState
  }
});

export const { addAction, removeAction, resetState } = wappSlice.actions;
export default wappSlice.reducer;
